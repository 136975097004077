import { isPointsActivityEnabled } from '@/features/rewards-summary/shared/utils';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { useFlagsReady } from '../flags-ready/use-flags-ready';

export const useProfileLinks = () => {
  const enabledMarketingConsent = useFlagsReady('marketing_consent');

  const data = useFeatureConfig();
  if (!data) {
    return [];
  }

  const profileLinks = [
    {
      href: '/rewards-summary/my-rewards',
      translation: 'My Rewards',
    },
  ];

  if (isPointsActivityEnabled(data.rewardsSummary)) {
    profileLinks.push({
      href: '/rewards-summary/points-activity',
      translation: 'Points Activity',
    });
  }

  if (enabledMarketingConsent) {
    profileLinks.push({
      href: '/account-preferences',
      translation: 'Account Preferences',
    });
  }

  return profileLinks;
};
