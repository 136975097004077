'use client';

import { useHermes } from '@/auth/use-hermes';
import { isMenuItem } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { Button } from '@/design-system-components/button/button';
import {
  CarouselContent,
  CarouselContext,
  CarouselItem,
} from '@/design-system-components/shared-carousel/carousel';
import { useCarousel } from '@/hooks/carousel/use-carousel';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, getAvatarNameFromUser } from '../avatar';
import { Icon } from '../icon';
import { MenuItemsPageType } from './mobile-header-v2';
import { MenuItem } from './navigation-menu-item';
import { customNavigation } from './utils';

/**
 * This components supports rendering multiple pages of menu items in a carousel.
 *
 * @param {MenuItemsPageType} menuItemsPage - The first menu items page to render.
 * @param {() => void} onClickMenuItem - The callback to call when a menu item is clicked.
 * @returns {React.ReactNode} The returned react component.
 */
export function MobileSideNavigation({
  menuItemsPage,
  onClickMenuItem,
  avatar,
}: {
  menuItemsPage: MenuItemsPageType;
  onClickMenuItem?: () => void;
  avatar: RewardsCentralConfiguration['application']['components']['header']['avatar'];
}) {
  const { logout, user } = useHermes();
  const { t } = useTranslation();
  const { setShowRedirectModal } = useContext(RedirectModalContext);

  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();
  const avatarName = useMemo(() => getAvatarNameFromUser(user), [user]);
  const fullName = `${user?.firstName} ${user?.lastName}`;

  const [menuItemsPages, setMenuItemsPages] = useState<MenuItemsPageType[]>([
    menuItemsPage,
  ]);
  const [navigationIndex, setNavigationIndex] = useState(1);
  const [nextAction, setNextAction] = useState<'next' | 'none'>('none');

  const { emblaRef, emblaApi, onNextButtonClick, onPrevButtonClick, ...props } =
    useCarousel({
      watchDrag: false,
      duration: 12,
    });

  useEffect(() => {
    if (emblaApi && nextAction === 'next' && emblaApi.canScrollNext()) {
      onNextButtonClick();
      setNextAction('none');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextAction, emblaApi?.canScrollNext()]);

  return (
    <CarouselContext.Provider
      value={{
        onNextButtonClick,
        onPrevButtonClick,
        carouselRef: emblaRef,
        api: emblaApi,
        ...props,
      }}
    >
      <div
        className={cn(
          'relative h-full w-full bg-[var(--rc-header--bg-color)] text-[var(--rc-header--color)]',
        )}
        role="region"
        aria-roledescription="carousel"
      >
        <CarouselContent viewableClassName="h-full" className="h-full">
          {menuItemsPages.map((menuItems, listId) => {
            const isFirstPage = listId === 0;

            return (
              <CarouselItem
                key={listId}
                className="relative flex h-full w-full shrink-0 flex-col"
              >
                <button
                  onClick={onClickMenuItem}
                  className="absolute right-0 z-10 flex h-6 w-6 items-center justify-center"
                  aria-label="Close Menu"
                >
                  <Icon name="xmark" className="h-5 w-5 text-neutral-500" />
                </button>

                {!!user && (
                  <div
                    className={cn('flex items-center gap-4 pb-6', {
                      hidden: !isFirstPage,
                    })}
                  >
                    <Avatar
                      name={avatarName}
                      picture={user.picture}
                      variant={avatar}
                    />
                    <div className="flex flex-col">
                      <div className="font-bold">${fullName}</div>
                      <div className="flex gap-2">
                        <Icon name="gem" className="h-4 w-4" />
                        {pointsFormatter(pointsAccount?.pointsBalance)}
                      </div>
                    </div>
                  </div>
                )}

                <NavigationBackButton
                  onClick={() => {
                    onPrevButtonClick();
                    setNavigationIndex((prev) => prev - 1);
                  }}
                  title={menuItems.groupName}
                  className={cn({ hidden: isFirstPage })}
                />

                {menuItems.items.map((item) => {
                  return isMenuItem(item) ? (
                    <MenuItem
                      href={item.href}
                      key={item.translation}
                      menuItem={item}
                      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        customNavigation(
                          item,
                          event,
                          setShowRedirectModal,
                          onClickMenuItem,
                        );
                      }}
                      displayMode="simple-with-icon"
                      className="flex-row items-center gap-3 px-0 py-4"
                    />
                  ) : (
                    <MenuItemsPageButton
                      item={item}
                      key={item.groupName}
                      className={cn(
                        'border-t border-primary-300 py-4 font-normal',
                        {
                          'mt-auto border-t-0': item.groupType === 'needHelp',
                        },
                      )}
                      onClick={(menuItemsPage: MenuItemsPageType) => {
                        setMenuItemsPages([
                          ...menuItemsPages.slice(0, navigationIndex),
                          menuItemsPage,
                        ]);
                        setNavigationIndex((prev) => prev + 1);
                        setNextAction('next');
                      }}
                    />
                  );
                })}

                <Button
                  variant={'primary'}
                  fullWidth={'always'}
                  textCenter
                  size={'md'}
                  className={cn('mt-6 rounded-custom bg-primary', {
                    hidden: !isFirstPage,
                  })}
                  onPress={() => {
                    logout?.();
                  }}
                >
                  {t('Logout')}
                </Button>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </div>
    </CarouselContext.Provider>
  );
}

interface NavigationBackButtonProps {
  onClick: () => void;
  title?: string;
  className?: string;
}

function NavigationBackButton({
  onClick,
  title,
  className,
}: NavigationBackButtonProps) {
  return (
    <button
      onClick={onClick}
      className={cn(
        'relative flex w-full flex-row items-center justify-center pb-4',
        className,
      )}
    >
      <Icon
        name="chevron-left"
        className="absolute left-0 h-4 w-4 text-neutral-500"
      />
      <div className="uppercase">{title}</div>
    </button>
  );
}

interface NavigationLinkProps {
  item: MenuItemsPageType;
  onClick: (navigationItems: MenuItemsPageType) => void;
  className?: string;
}

function MenuItemsPageButton({
  item,
  onClick,
  className,
}: NavigationLinkProps) {
  return (
    <button
      onClick={() => onClick(item)}
      className={cn('flex flex-row items-center justify-between', className)}
    >
      <div className="flex flex-row items-center gap-3">
        {item.icon ? <Icon name={item.icon} className="h-4 w-4" /> : null}
        <div>{item.groupName}</div>
      </div>

      <Icon name="chevron-right" className="h-4 w-4 text-primary-300" />
    </button>
  );
}
