'use client';

import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { useTermsAndConditionsModalContent } from '@/hooks/static-documents/use-static-documents';
import {
  useUserCustomizations,
  useUserCustomizationsMutation,
} from '@/hooks/user-customizations/use-user-customizations';
import { useRouter } from '@/i18n/navigation';
import { useIsEnableTermsAndConditionsModal } from '@/store/store';
import { useEffect, useState } from 'react';
import { AllTermsModal } from './all-terms-modal/all-terms-modal';
import { MarketingConsentModal } from './marketing-consent-modal/marketing-consent-modal';

type AllTermsSubmitData = {
  isAcceptedTc: true;
  policyVersion: string;
  termsVersion: string;
  serviceTermsVersion: string;
  marketingEmailConsent?: boolean | undefined;
};

type MarketingTermsSubmitData = {
  marketingEmailConsent: boolean;
};

enum TcMode {
  'default',
  'marketing',
}

interface BaseTcModalProps {
  isOpen: boolean;
  isSubmitError: boolean;
  data: ReturnType<typeof useData>['data'];
  isLoadingTcContent: boolean;
}

export interface MarketingTcModalProps extends BaseTcModalProps {
  onSubmit: (data: MarketingTermsSubmitData) => Promise<void>;
}

export interface AllTcModalProps extends BaseTcModalProps {
  marketingConsentEnabled: boolean;
  onSubmit: (data: AllTermsSubmitData) => Promise<void>;
}

export function TermAndConditionsModal() {
  const marketingConsentEnabled = useFlagsReady('marketing_consent');

  const router = useRouter();
  const {
    data: acceptedTerms,
    isLoading,
    isError: isErrorIsAcceptedTc,
  } = useUserCustomizations({
    select: (data) => {
      return {
        marketingEmailConsent: data.marketingEmailConsent,
        isAcceptedTc: data.isAcceptedTc,
      };
    },
  });

  const isEnableTermsAndConditionsModal = useIsEnableTermsAndConditionsModal();
  const isAcceptedTcMutation = useUserCustomizationsMutation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    data,
    isLoading: isLoadingTcContent,
    isError: isErrorTcContent,
  } = useData();

  useEffect(() => {
    // we use the isOpen instead of isAcceptedTc because we want to
    // dismiss the modal when the user accepts the tc
    // without waiting for the API to return the value
    if (!acceptedTerms) return;

    const requireUserAction =
      !acceptedTerms.isAcceptedTc ||
      (marketingConsentEnabled && acceptedTerms.marketingEmailConsent == null);

    setIsOpen(requireUserAction);
  }, [acceptedTerms, marketingConsentEnabled]);

  useEffect(() => {
    if (isErrorIsAcceptedTc) {
      router.push(
        // TODO: update message
        '/failure?error=Cannot+check+terms+and+conditions+acceptance&code=FE0000&httpCode=200',
      );
    }
  }, [isErrorIsAcceptedTc, router]);

  useEffect(() => {
    if (isErrorTcContent) {
      setIsOpen(false);
    }
  }, [isErrorTcContent]);

  const onSubmit = async (
    submitData: AllTermsSubmitData | MarketingTermsSubmitData,
  ) => {
    return await isAcceptedTcMutation
      .mutateAsync(submitData)
      .then(() => {
        setIsOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
        // TODO: show inline error message
      });
  };

  // only the term and conditions modal if the user has not accepted the tc and
  // the API has returned the value
  if (!isEnableTermsAndConditionsModal || isLoading || isErrorIsAcceptedTc) {
    return null;
  }

  const mode = acceptedTerms?.isAcceptedTc ? TcMode.marketing : TcMode.default;

  if (mode === TcMode.default) {
    return (
      <AllTermsModal
        marketingConsentEnabled={marketingConsentEnabled}
        isOpen={isOpen}
        isSubmitError={isAcceptedTcMutation.isError}
        onSubmit={onSubmit}
        data={data}
        isLoadingTcContent={isLoadingTcContent}
      />
    );
  }

  if (mode === TcMode.marketing) {
    return (
      <MarketingConsentModal
        isOpen={isOpen}
        isSubmitError={isAcceptedTcMutation.isError}
        onSubmit={onSubmit}
        data={data}
        isLoadingTcContent={isLoadingTcContent}
      />
    );
  }
}

export function useData() {
  const { data, isLoading, isError } = useTermsAndConditionsModalContent();
  const router = useRouter();

  useEffect(() => {
    if (data === null) {
      // TODO: log Sentry error
      router.push(
        // TODO: update message
        '/failure?error=Some+Terms+and+Conditions+content+are+missing&code=FE0002&httpCode=200',
      );
    }
  }, [data, router]);

  useEffect(() => {
    if (isError) {
      // TODO: log Sentry error
      router.push(
        // TODO: update message
        '/failure?error=Cannot+get+Terms+and+Conditions+content&code=FE0001&httpCode=200',
      );
    }
  }, [isError, router]);

  return {
    isError,
    isLoading,
    data,
  };
}
