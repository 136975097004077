'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React, { forwardRef, MouseEventHandler } from 'react';

import { Icon } from '@/components/icon';
import { MenuItemType } from '@/config-schema/navigation';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import useLoyaltyPrograms from '@/hooks/loyalty-programs/use-loyalty-programs';
import { Link } from '@/i18n/navigation';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

// CSS variables
// --menu-item--color
// --menu-item--hover-color
// --menu-item--hover-bg-color
// --menu-item--icon-color
// --menu-item--description-color

export const NavigationMenuItem = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & {
    menuItem: MenuItemType;
    className?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    displayMode?: 'full' | 'simple' | 'simple-with-icon';
  }
>((props) => {
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link asChild>
        <MenuItem {...props} />
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
});

export const MenuItem = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & {
    menuItem: MenuItemType;
    className?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    displayMode?: 'full' | 'simple' | 'simple-with-icon';
  }
>(({ className, menuItem, displayMode, href, ...props }, ref) => {
  const { t } = useTranslation();
  const m2020ListingRedirect = useFlagsReady('m2020_listing_redirect');
  let internalHref = href;

  const { loyaltyPrograms: carbonLoyaltyPrograms } = useLoyaltyPrograms({
    sortBy: 'default',
    loyaltyProgramType: 'sustainability',
  });

  const { loyaltyPrograms: cryptoLoyaltyPrograms } = useLoyaltyPrograms({
    sortBy: 'default',
    loyaltyProgramType: 'crypto',
  });

  if (m2020ListingRedirect) {
    if (
      href === '/products/crypto' &&
      cryptoLoyaltyPrograms?.data.length === 1
    ) {
      internalHref = `/products/crypto/${cryptoLoyaltyPrograms.data[0].id}`;
    }

    if (
      href === '/products/sustainability' &&
      carbonLoyaltyPrograms?.data.length === 1
    ) {
      internalHref = `/products/sustainability/${carbonLoyaltyPrograms.data[0].id}`;
    }
  }

  const isExternalUrl = `${internalHref}`?.startsWith('http');
  const isGuardhouseUrl = `${internalHref}`?.startsWith('/auth/');

  let menuItemComponent = null;

  if (displayMode === 'full') {
    menuItemComponent = (
      <>
        <span className="grow text-[var(--rc-menu-item--description-color)]">
          <strong className="block">{t(menuItem.translation)}</strong>
          <span className={cn('mt-1 block')}>{menuItem.shortDescription}</span>
        </span>
        {!!menuItem.iconVisible && menuItem.iconName && (
          <Icon
            href={menuItem.iconHref}
            name={menuItem.iconName}
            className={cn(
              'mt-1 h-4 w-4 flex-shrink-0',
              'self-baseline',
              'fill-[var(--rc-menu-item--icon-fill-color)]',
            )}
          />
        )}
      </>
    );
  } else if (displayMode === 'simple-with-icon') {
    menuItemComponent = (
      <>
        {!!menuItem.iconVisible && menuItem.iconName && (
          <Icon
            href={menuItem.iconHref}
            name={menuItem.iconName}
            className={cn(
              'mt-1 h-4 w-4 flex-shrink-0',
              'self-baseline',
              'fill-[var(--rc-menu-item--icon-fill-color)]',
            )}
          />
        )}
        {t(menuItem.translation)}
      </>
    );
  } else {
    menuItemComponent = t(menuItem.translation);
  }

  return (
    <Link
      className={cn(
        'flex flex-row-reverse gap-4 rounded px-6 py-4',
        'text-[var(--rc-menu-item--color)]',
        'hover:text-[var(--rc-menu-item--hover-color)]',
        'hover:bg-[var(--rc-menu-item--hover-bg-color)]',
        className,
      )}
      {...props}
      href={internalHref}
      ref={ref}
      target={isExternalUrl ? '_blank' : '_self'}
      rel={isExternalUrl ? 'noopener noreferrer' : undefined}
    >
      {menuItemComponent}
    </Link>
  );
});

MenuItem.displayName = 'MenuItem';
NavigationMenuItem.displayName = 'NavigationMenuItem';
