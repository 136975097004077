import { z } from 'zod';

export const userCustomizationsSchema = z.object({
  isAcceptedTc: z.boolean().optional(),
  marketingEmailConsent: z.boolean().optional(),
  offerSearchLocations: z
    .array(
      z.object({
        viewedAt: z.date(),
        fullAddress: z.string(),
      }),
    )
    .optional(),
});

export type UserCustomizationsResponse = z.infer<
  typeof userCustomizationsSchema
>;

export type UserCustomizations = UserCustomizationsResponse;
