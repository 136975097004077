'use client';

import { FlagProvider, useUnleashContext, type IToggle } from '@unleash/nextjs';
import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { useUser } from '../app/auth-store';

const PRODUCTION_UNLEASH_KEY =
  '*:production.ed9dc866a8362d926412ad673806fed91176182036fdca279f983b42';
const STAGING_UNLEASH_KEY =
  '*:development.23acaf6ca5485c9ed7a23766b5fd67b8c96e4439247906c9a8b2d070';

const UNLEASH_URL = 'https://us.app.unleash-hosted.com/usee0016/api/frontend';

type UnleashConfig = NonNullable<ComponentProps<typeof FlagProvider>['config']>;

export default function FlagProviderWrapper({
  environment,
  children,
  tenantId,
  flagOverrides,
}: {
  environment: string;
  children: ReactNode;
  tenantId: string;
  flagOverrides?: Record<string, boolean>;
}) {
  const [config] = useState<UnleashConfig>(() => {
    return {
      environment,
      url: UNLEASH_URL,
      appName: 'rc-us',
      context: {
        tenant_id: tenantId,
        properties: {
          // this line only executes on the client side
          domain: typeof window === 'undefined' ? '' : window.location.hostname,
        },
      },
      clientKey:
        environment === 'production'
          ? PRODUCTION_UNLEASH_KEY
          : STAGING_UNLEASH_KEY,
      refreshInterval: 60,
      bootstrap: flagOverrides ? kvToToggles(flagOverrides) : undefined,
    };
  });

  return (
    <FlagProvider config={config}>
      <UpdateFlagProvider tenantId={tenantId}>{children}</UpdateFlagProvider>
    </FlagProvider>
  );
}

function UpdateFlagProvider({
  tenantId,
  children,
}: {
  tenantId: string;
  children: ReactNode;
}) {
  const user = useUser();
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({
      userId: user?.sub,
      // @ts-expect-error workaround
      // we can include additional properties in context
      // but the type definition is not correct
      // @deprecated - use tenantId instead
      tenant_id: tenantId,
    });
  }, [updateContext, tenantId, user?.sub]);

  return children;
}

function kvToToggles(kv: Record<string, boolean>): IToggle[] {
  return Object.entries(kv).map(([name, enabled]) => ({
    name,
    enabled,
    variant: { name: 'default', enabled },
    impressionData: false,
  }));
}
